<template>
  <div>
    <BrandChooseWindow />
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo-all-dark.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--17">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100">
              <!-- <h2 class="heading-title theme-gradient">Cntact With Us</h2> -->
              <p>
                <!-- Contrary to popular belief, Lorem Ipsum is not simply random
                text. -->
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Contact Address Area  -->
    <div class="rn-contact-address-area rn-section-gap bg_color--5">
      <v-container>
        <ContactAddress />
      </v-container>
    </div>
    <!-- End Contact Address Area  -->

    <!-- Start Contact Area  -->
    <div class="contact-form--1">
      <v-container>
        <Contact>
          <img
            slot="contact-img"
            src="../assets/images/about/about-6.jpg"
            alt="health_e"
          />
        </Contact>
      </v-container>
    </div>
    <!-- End Contact Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../components/header/HeaderOnePage";
import ContactAddress from "../components/contact/ContactAddress";
import Contact from "../components/contact/Contact";
import BrandChooseWindow from "../components/brand/BrandChooseWindow";
import Footer from "../components/footer/FooterTwo";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: { Header, ContactAddress, Contact, BrandChooseWindow, Footer },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["token"]),
  },
  async created() {
    await this.getUser();
  },
  methods: {
    ...mapActions(["getUser"]),
    ...mapActions(["getToken"]),
  },
};
</script>

<style></style>
